/*global Component*/
class imgZoomComponent extends Component {

    static  name() {
        return "imgZoomComponent";
    }

    static componentName() {
        return "imgZoomComponent";
    }
    data() {
        return {
            isMounted: false,
            scaleFactor: 1.1
        };
    }

    getProps() {
        return {
            useAsModal:{
                type:Boolean,
                default: false
            },
            'src':String,
            'myclass':String,
            'mystyle':{
                'type':Object,
                'defautl':{}
            },
            "isthumbnails":Boolean,
            "eventclick":Function,
            "imgIndex": Number
        };
    }

    getComputed() {
        return {
            image(){
                return this.getImageUrl();
            },
            containerWidth(){
                if(this.isMounted) {
                    return document.getElementById('zoomImage').offsetWidth * this.scaleFactor;
                }
                return 0;
            },
            containerHeight(){
                if(this.isMounted) {
                    return document.getElementById('zoomImage').offsetWidth * this.scaleFactor;
                }
                return 0;
            }
        };
    }

    getWatch() {
        return  {
            'showZoom':function (newValue,oldValue){
                if(newValue)
                    this.imageZoom();
            }
        };
    }

    getMethods() {
        return {
            getImageUrl:this.getImageUrl
        };
    }

    mounted() {
        return function () {
            try {
                this.$nextTick(function () {
                    this.isMounted = true;
                });
            }
            catch (error){
                console.error(error);
            }
        };
    }


    getImageUrl(){
        return this.src + "&ContentDisposition=inline&noforce=false" || "../static/ecommerce/img/nophoto_128x128.jpg";
    }

    getTemplate(){
        return `<div key="zoomImage" id="zoomImage"  class="img-zoom-container" >
                     <image-magnifier :src="image"
                                        :zoom-src="image"
                                        width="100%"
                                        height="100%"
                                        :zoom-width="containerWidth"
                                        :zoom-height="containerHeight">
                     </image-magnifier>
                </div>`;
    }

    getTemplate2() {
        return `<div key="zoomImage" class="img-zoom-container"  @mouseleave="disableZoom" @mouseenter="activateZoom"   >
                    <div ref="imgZoomLens2" id="imgZoomLens2"  class='img-zoom-lens2' :class="{'d-none':!showZoom}" :style="styleObjectMap" ></div>
                    <div ref="myresult" id="myresult" class="img-zoom-result" :class="{'d-none':!showZoom}" :style="styleZoomImg"></div>
                    <div ref="myimage" id="myimage" class="min-vh-50"  :class="classObjectMap"  :style="styleImageMap" alt="Product Image"></div>
                    <p>{{debug}}</p>
                </div>`;
    }
}

imgZoomComponent.registerComponent();